import React, { useEffect, useState } from "react";

import './AdminPaid.css';
import { CardGradient } from "../CardGradient/CardGradient";
import { GoBack } from "../GoBack/GoBack";
import { CreditNumber } from "../CreditNumber/CreditNumber";
import { ButtonGradient } from "../ButtonGradient/ButtonGradient";
import { CreditCard } from "../CreditCard/CreditCard";
import { useSelector } from "react-redux";
import { Loader } from "../Loader/Loader";
import { toast } from "react-toastify";
import { addPaymentMethod, deletePaymentMethod, getPaymentMethodByUserId } from "../../Service/PaymentMethod";
import { PaymentMethodInterface } from "../../Interfaces/PaymentMethodInterface";
import { AddPaymentInterface } from "../../Interfaces/AddPaymentInterface";
import { Close } from "../../Icons/outline/Close";
import { updateSuscription } from "../../Service/Subscription.service";
import { MercadoPagoCheckout } from "../MercadoPago/MercadoPagoCheckout";
import { ModalCheckout } from "../ModelCheckout/ModalCheckout";

interface AdminPaidProps {
    subscriptionId: string;
    handleGoBack: () => void;
}
export const AdminPaid: React.FC<AdminPaidProps> = ({
    subscriptionId,
    handleGoBack = () => {}
}) => {

    const {userDetail} = useSelector((state: any) => state.user);

    const [loading, setLoading] = useState<boolean>(true);

    const [showModal, setShowModal] = useState<boolean>(false);

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethodInterface[]>([]);

    const handleSelectClick = async (paymentMethod: PaymentMethodInterface) => {
        // console.log("🚀 ~ handleSelectClick ~ paymentMethod:", paymentMethod, subscriptionId);
        if(paymentMethod.active) {
            toast.warning("La tarjeta seleccionada ya está activa");
        } else {
            toast.warning("en mantenimiento");
            // setLoading(true);
            // try {
            //     const response = await updateSuscription({
            //         paymentMethodId: paymentMethod.id || "",
            //         subscriptionId: subscriptionId
            //     });
            //     if(response) {
            //         toast.success("Tarjeta seleccionada correctamente", {autoClose: 6000});
            //         getPaymentMethods();
            //     } else {
            //         toast.error("Error al seleccionar la tarjeta");
            //     }
            //     setLoading(false);
            // } catch (error) {
            //     console.log("🚀 ~ handleSelectClick ~ error:", error);
            //     setLoading(false);
            //     toast.error("Error al seleccionar la tarjeta");
            // }
        }
    }

    const handleSaveCardClick = async (token: string) => {
        setLoading(true);
        // console.log("🚀 ~ handleSaveCardClick ~ token", token);
        const tmpPaymentMethod: AddPaymentInterface = {
            token: token,
            userId: userDetail.id
        };
        try {
            const response = await addPaymentMethod(tmpPaymentMethod);
            console.log("🚀 ~ handleSaveCardClick ~ response:", response)
            setLoading(false);
            if(response){
                getPaymentMethods();   
                toast.success("Tarjeta guardada correctamente");           
            } else {
                toast.error("Error al guardar la tarjeta");
            }
        } catch (error:any) {
            console.log("🚀 ~ handleSaveCardClick ~ error:", error);
            setLoading(false);
            toast.error("Error al guardar la tarjeta, por favor intente con otra", {autoClose: 6500});
        }

    }

    const handleAddCard = () => {
        setShowModal(true);
    }

    const handleDeletePaymentMethod = async (paymentMethod: PaymentMethodInterface) => {
        // console.log("🚀 ~ handleDeletePaymentMethod ~ paymentMethod", paymentMethod)
        if(!paymentMethod.active) { 
            toast.warning("en mantenimiento");
            // setLoading(true);
            // try {
            //     const response = await deletePaymentMethod(paymentMethod.id || "");
            //     // console.log("🚀 ~ handleDeletePaymentMethod ~ response:", response);
            //     toast.error("Tarjeta eliminada correctamente");
            //     getPaymentMethods(); 
            //     setLoading(false);
            // } catch (error) {
            //     console.log("🚀 ~ handleDeletePaymentMethod ~ error:", error);
            //     setLoading(false);
            //     toast.error("Hubo un error al eliminar la tarjeta, por favor inténtelo más tarde.");
            // }
        } else {
            toast.error("No es posible eliminar la tarjeta seleccionada, ya que está asociada a una suscripción activa.", {autoClose: 6500});
        }
    }

    // method to get the payment methods
    const getPaymentMethods = async () => {
        setLoading(true);
        try {
            const response = await getPaymentMethodByUserId(userDetail.id);
            setPaymentMethods(response);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log("🚀 ~ getPaymentMethods ~ error:", error)
            toast.error("Error al obtener los métodos de pago");
        }
    }

    useEffect(() => {
        getPaymentMethods();
    }, []);

  return (
    <> <CardGradient className="adminPaid">
        <div className="adminPaid-row"  onClick={()=>handleGoBack()}>
            <GoBack/>
            <div className="paragraph-subheader adminPaid-text-white">Administrar forma de pago</div>
        </div>
        
        {!loading ? <>
            {paymentMethods.length > 0 && paymentMethods.map((p, idx) => (
                <CardGradient className="adminPaid-card" key={idx}>
                    <CreditNumber textCard 
                        brandCard={p.cardBrand}
                        lastDigits={p.lastFour}    
                    />
                    <div className="adminPaid-left">
                        <ButtonGradient 
                            className={p.active ? "button--gradient-text-active" : ""}
                            content={p.active ? "Tarjeta seleccionada" : "Utilizar tarjeta" } 
                            handleClick={()=>handleSelectClick(p)}/>
                        <Close className="adminPaid-close" onClick={()=>handleDeletePaymentMethod(p)}  />
                    </div>
                </CardGradient>
            ))}
            <div className="paragraph-subheader adminPaid-text-white adminPaid-text" onClick={()=>handleAddCard()}>+ Agregar forma de pago</div>    
        </>: <div className="adminPaid-loader">
            <Loader />
        </div>}       
    </CardGradient>
    {userDetail  && showModal && <ModalCheckout 
        modalTitle="Agregar tarjeta"
        show={showModal}
        setShow={setShowModal}
        amount={1}
        email={userDetail.email}
        typeIt={userDetail.typeIt}
        itNumber={userDetail.itNumber}
        handleProcessCheckout={handleSaveCardClick}
    />}


    </>
  )
}