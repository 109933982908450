import React, { useEffect, useState } from "react";

import './ModalCheckout.css';
import { Modal } from "../Modal/Modal";
import { TypeIdentify } from "../../@types/TypeIdentify";
import { MercadoPagoCheckout } from "../MercadoPago/MercadoPagoCheckout";
import { LinearLoader } from "../LinearLoader/LinearLoader";

interface ModalCheckoutProps {
    modalTitle: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    amount: number; // amount to pay
    email: string // email of the user
    typeIt: TypeIdentify; // type of identify
    itNumber: string; // number of identify
    handleProcessCheckout: (token: string) => void;
}

export const ModalCheckout: React.FC<ModalCheckoutProps> = ({
    modalTitle,
    show,
    setShow,
    amount,
    email,
    typeIt,
    itNumber,
    handleProcessCheckout,
}) => {

    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    useEffect(() => {
        if (show) {
          // Bloquear el scroll añadiendo una clase al body
          document.body.style.overflow = 'hidden';
        } else {
          // Restaurar el scroll
          document.body.style.overflow = 'auto';
        }
      }, [show]);

    const handleProcessCheckoutClick = (token: string) => {
        console.log("🚀 ~ handleProcessCheckout ~ token:", token);  
        setIsProcessing(true);
        handleProcessCheckout(token);
        setTimeout(() => {
            setShow(false);
            setIsProcessing(false);
        }, 5000);
    }

    return (
        <Modal className="modalCheckout-container" title={!isProcessing ? modalTitle: "Procesando"} show={show} setShow={!isProcessing ? setShow: ()=>{}}   >
            {!isProcessing ? <MercadoPagoCheckout 
                amount={amount}
                email={email}
                typeIt={typeIt}
                itNumber={itNumber}
                onPayClick={handleProcessCheckoutClick}
            /> : <div className="modalcheckout-mesagge paragraph">
                Por favor, no cierres ni actualices la página mientras completamos tu transacción. Este proceso puede tardar unos minutos.
                <LinearLoader />
            </div>}
        </Modal>
    )
}