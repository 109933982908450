import React, { useEffect, useState } from "react";

// Importando estilo
import "./ReffledProducts.css";
import { Product } from "../Product/Product";
// import { reffledProducts } from "../../Const/reffledProductsConst";
import { HeaderSection } from "../HeaderSection/HeaderSection";
import { RaffledProductInterface } from "../../Interfaces/RaffledProductInterface";
import { GetLastFourReffledProducts } from "../../Service/ReffledProduct.service";
import { ReffledProductLoader } from "../ContentLoader/ReffledProduct";

interface ReffledProductsProps {
    onClickProduct: () => void;
}

export const ReffledProducts: React.FC<ReffledProductsProps> = ({
    onClickProduct
}) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [reffledProducts, setReffledProducts] = useState<RaffledProductInterface[]>([]);

    const getAllReffledProducts = async () => {
        if(!loading) {
            setLoading(true);
            try {               
                const response = await GetLastFourReffledProducts();
                setReffledProducts(response);
                setLoading(false);
            } catch (error) {
                console.log("🚀 ~ getAllReffledProducts ~ error:", error);
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        getAllReffledProducts();
    }, []);

    return (
        <section id="reffledProducts" className="reffledProducts">
            <HeaderSection 
                title="Conoce tóh lo que tengo pa ti"
                subTitle="Sorteos de TODO lo que podrás querer e imaginar, TODAS las semanas."
            />  

            <div className="reffledProducts-scroll">
         
                <div className="reffledProducts-row">
                   {reffledProducts.length>0 && reffledProducts.slice(0,4).map((item, idx) => {
                        return (
                            <Product
                                key={idx}
                                position="column"
                                product={item}
                                handleClick={onClickProduct}
                                footer
                            />
                        )
                    })}
                    {/* <ReffledProductLoader/> */}
                </div>
     
            </div>
        </section>
    );
};
