import axios from "axios";

export const GetIpHost = async () => { 
    try {
        const response = await axios.get("https://api.ipify.org/?format=json");
        return response.data.ip;
    } catch (error) {
        console.log("🚀 ~ file: getIp.service.ts:5 ~ GetIpHost ~ error:", error)
        return error;
    }
};