import React, { useEffect, useState } from "react";

import './RegisterFull.css';
import { Dropdown } from "../Dropdown/Dropdown";
import { Button } from "../Button/Button";
import { CardBackground } from "../CardBackground/CardBackground";
import { TextField } from "../TextField/TextField";
import { UserInterface } from "../../Interfaces/UserInterface";
import { CountryList } from "../CountryList/CountryList";
import { TypeItConst } from "../../Const/TypeItConst";
import { PhoneCountry } from "../PhoneCountry/PhoneCountry";
import { FormatDate } from "../../Utils/formatDate";
import { FormatISODate } from "../../Utils/FormatISODate";
import { userErrorInterface } from "../../Interfaces/UserError.interface";
import { Validatefield } from "../../Utils/ValidateFields";
import { GenderConst } from "../../Const/GenderConst";
import { IpData } from "../../Interfaces/IpDataInterface";
import { getIpData } from "../../Service/GetIpData.service";
import { COUNTRY_FLAG_BASE } from "../../Const/BaseUrlConst";

interface RegisterFullProps {
    newUser: UserInterface
    handleInputchange: (value: string, key: string) => void;
    handleChangeCountry: (name: string, imgFlag: string, alpha2Code: string) => void;
    handlePhoneChange: (value: string, field: "phone" | "phoneCode" | "phoneFlag") => void;
    handleClick: () => void;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RegisterFull: React.FC<RegisterFullProps> = ({
    newUser,
    handleInputchange,
    handleChangeCountry,
    handlePhoneChange,
    handleClick,
    loading,
    setLoading
}) => {


    const [fullRegisterError, setFullRegisterError] = useState<userErrorInterface>({
        name: {status: false, message: ""},
        fathersLastName: {status: false, message: ""},
        mothersLastName: {status: false, message: ""},
        city: {status: false, message: ""},
        country: {status: false, message: ""},
        typeDocument: {status: false, message: ""},
        itNumber: {status: false, message: ""},
        phoneCode: {status: false, message: ""},
        phone: {status: false, message: ""},
        birthday: {status: false, message: ""},
        direction: {status: false, message: ""},
        gender: {status: false, message: ""},
    });

    const [ipData, setIpData] = useState<IpData>();

    // method to validate fields
    const handleValidateFields = () => {
        let error = false;

        error = Validatefield("required",  newUser.name) || Validatefield("required", newUser.fathersLastName) || 
            Validatefield("required",  newUser.mothersLastName) || Validatefield("required",  newUser.country.name) ||
            Validatefield("required",  newUser.typeIt) || Validatefield("DNI",  newUser.itNumber.toString()) ||
            Validatefield("required",  newUser.phoneCode) || Validatefield("required",  newUser.phone.toString()) ||
            Validatefield("required",  newUser.birthday) || Validatefield("required",  newUser.gender);
            // Validatefield("required",  newUser.direction);

        setFullRegisterError((prev) => ({
            name: {status: Validatefield("required",  newUser.name), message: "Ingresa tu nombre"},
            fathersLastName: {status: Validatefield("required", newUser.fathersLastName), message: "Ingresa tu apellido paterno"},
            mothersLastName: {status: Validatefield("required",  newUser.mothersLastName), message: "Ingresa tu apellido materno"},
            city: {status: Validatefield("required",  newUser.city), message: "Ingresa tu ciudad"},
            country: {status: Validatefield("required",  newUser.country.name), message: "Ingresa tu país"},
            typeDocument: {status: Validatefield("required",  newUser.typeIt), message: "Selecciona tu tipo de documento"},
            itNumber: {status: Validatefield(newUser.typeIt === "DNI" ? "DNI" : "required",  newUser.itNumber.toString()), message: "Ingresa un número de documento válido"},
            phoneCode: {status: Validatefield("required",  newUser.phoneCode), message: "Ingresa el código de tu país"},
            phone: {status: Validatefield("required",  newUser.phone.toString()), message: "Ingresa tu número de teléfono"},
            birthday: {status: Validatefield("required",  newUser.birthday), message: "Ingresa tu fecha de nacimiento"},
            gender: {status: Validatefield("required",  newUser.gender), message: "Selecciona tu genero"},
            direction: {status: false, message: "Ingresa tu dirección"},
        }));

        return error;
    }

    const handleContinueClick = () => {
        if(handleValidateFields()){
            return;
        }
        setLoading(true);
        handleClick();
    }

    const getDataFromIp = async () => {
        try {
            const response = await getIpData();
            setIpData(response);
            handleInputchange(response.country_name === "Peru" ? "DNI" : "", "typeIt")
            handlePhoneChange(response.country_calling_code, "phoneCode");
            handlePhoneChange(COUNTRY_FLAG_BASE+response.country_code.toLowerCase()+".svg", "phoneFlag");
            handleChangeCountry(response.country_name, COUNTRY_FLAG_BASE+response.country_code.toLowerCase()+".svg", response.country_code);
            handleInputchange(response.city, "city");
        } catch (error) {
            console.log("🚀 ~ getIpData ~ error:", error)
            throw error;
        }
    }

    useEffect(() => {
        getDataFromIp();
    }, []);

    
    return (
        <div className="registerFull">
            <CardBackground>
                <TextField
                    label="Nombres"
                    placeholder="Ingresa tus nombres"
                    value={newUser.name}
                    onChange={(e)=> handleInputchange(e.target.value, "name")}
                    error={fullRegisterError.name}
                />
                <TextField
                    label="Apellido Paterno"
                    placeholder="Ingresa apellido paterno"
                    value={newUser.fathersLastName}
                    onChange={(e)=> handleInputchange(e.target.value, "fathersLastName")}
                    error={fullRegisterError.fathersLastName}
                />
                <TextField
                    label="Apellido Materno"
                    placeholder="Ingresa apellido materno"
                    value={newUser.mothersLastName}
                    onChange={(e)=> handleInputchange(e.target.value, "mothersLastName")}
                    error={fullRegisterError.mothersLastName}
                />

                <Dropdown
                    name="Tipo de documento"
                    items={TypeItConst}
                    handleItemClick={(label) => handleInputchange(label, "typeIt")}
                    error={fullRegisterError.typeDocument}
                    value={newUser.typeIt}
                />
                <TextField
                    label={`Número de ${newUser.typeIt.length>0 ? newUser.typeIt : "documento"}`}
                    placeholder="Ingresa el número"
                    value={newUser.itNumber === 0 ? "" : newUser.itNumber.toString()}
                    onChange={(e)=> handleInputchange(e.target.value, "itNumber")}
                    error={fullRegisterError.itNumber}
                />
                {/* <TextField
                    label="Número de teléfono"
                    placeholder="Teléfono"
                    value={newUser.phone === 0 ? "" : newUser.phone.toString()}
                    onChange={(e)=> handleInputchange(e.target.value, "phone")}
                /> */}
                <PhoneCountry
                    handleCountryChange={(value)=> handlePhoneChange(value, "phoneFlag")}
                    handlePhoneCodeChange={(value)=>handlePhoneChange(value, "phoneCode")}
                    handlePhoneChange={(value)=>handleInputchange(value.target.value, "phone")}
                    phone={newUser.phone ? newUser.phone.toString() : ""}
                    phoneCode={newUser.phoneCode}
                    phoneFlag={newUser.phoneFlag}
                    error={{
                        phone: fullRegisterError.phone,
                        phoneCode: fullRegisterError.phoneCode
                    }}
                />
                <TextField
                    label="Fecha de nacimiento"
                    placeholder="dd/mm/aaaa"
                    value={newUser.birthday}
                    onChange={(e)=> handleInputchange(e.target.value, "birthday")}
                    error={fullRegisterError.birthday}
                />
                 <Dropdown
                    name="Género" 
                    items={GenderConst}
                    handleItemClick={(label) => handleInputchange(label, "gender")}
                    error={fullRegisterError.gender}
                    value={newUser.gender}
                />
                 <CountryList
                    name="País"
                    onClick={handleChangeCountry}
                    value={newUser.country.name}
                    valueFlag={newUser.country.imgFlag}
                    error={fullRegisterError.country}
                />
                 <TextField
                    label="Ciudad"
                    placeholder="Ingresa la cuidad"
                    value={newUser.city}
                    onChange={(e)=> handleInputchange(e.target.value, "city")}
                    error={fullRegisterError.city}
                />
                {/* <TextField
                    label="Dirección exacta"
                    placeholder="Ingresa la dirección"
                    value={newUser.direction}
                    onChange={(e)=> handleInputchange(e.target.value, "direction")}
                    error={fullRegisterError.direction}
                /> */}

                <Button
                    text={loading ? "Cargando..." : "Continuar"}
                    color="orange"
                    onClick={() => !loading ? handleContinueClick() : {}}
                />
            </CardBackground>
        </div>
    )
}