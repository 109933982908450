import { TypeIdentify } from "../@types/TypeIdentify";

export const TypeItConst: {
    id: number;
    label: TypeIdentify;
}[] = [{
    id: 0,
    label: 'DNI'
}, {
    id: 1,
    label: 'Pasaporte'
}, {
    id: 2,
    label: 'Carnet de extranjeria'
}, {
    id: 3,
    label: 'Nro de cédula'
}]
