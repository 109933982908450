import React, { useEffect, useState } from "react";

// Importando estilo
import "./Plan.css";
import { Button } from "../Button/Button";
import { Item } from "../Item/Item";
import { PlanInterface } from "../../Interfaces/PlansInterface";
import { ButtonGradient } from "../ButtonGradient/ButtonGradient";
import { CardGradient } from "../CardGradient/CardGradient";
import { STORAGE_ASSETS } from "../../Const/BaseUrlConst";

interface PlanProps {
    plan: PlanInterface
    onclickPlan: (plan: PlanInterface) => void;
    index?: number;
}

export const Plan: React.FC<PlanProps> = ({
    plan,
    onclickPlan,
    index = 0
}) => {

  
    return (
        <CardGradient className={`plan-gradient plan-${index}`}>
            <div className="plan">
                <div className="plan-body-first">
                    <div className="plan-header-price">
                        {plan.isPopular && 
                        <CardGradient className="plan-popular">
                            <div className="smalltext-header plan-text">
                                Más popular
                            </div>
                        </CardGradient>}

                        <div className="plan-header">
                            <div className="small-bold plan-text">{plan.title}</div>
                            <div className="plan-price">
                                <div className="big-header plan-text">{plan.price + '0'}</div>
                                <div className="smalltext plan-text">USD</div>
                            </div>
                            <div className="tinytext-header-1 plan-text">{plan.period}</div>
                        </div>
                    </div>

                    <div className="plan-body smalltext plan-text">
                        {plan.detail}
                    </div>
                </div>
                {/* <Button 
                    text={plan.callToAction} 
                    onClick={()=>{}}
                    radius="btn-radius-20"
                /> */}
                <div className="plan-button" style={{backgroundImage:`url(${STORAGE_ASSETS}/images/png/selectBackground.png`}}>
                    <ButtonGradient
                        handleClick={() => onclickPlan(plan)}
                        content={plan.callToAction}
                    />
                </div>
                <div className="plan-detail-item">
                    {plan && plan.planItems && plan.planItems.length>0 && plan.planItems.map((item, idx) => {
                        return (
                            <Item
                                key={idx}
                                itemLabel={item}
                            />
                        )
                    })}
                </div>
                {plan.announcement && 
                    <div className="tinytext-header plan-text  plan-announcement">{plan.announcement}</div>}

                <div className="tinytext plan-text plan-footer">
                    {plan.planImport}
                </div>
            </div>
        </CardGradient>
    );
};
