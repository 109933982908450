import React from "react";

import "./LinearLoader.css";

export const LinearLoader: React.FC<{}> = ({ ...props }) => {
    return (
       <div className="liner-container">
            <span {...props} className="linear"></span>
       </div>
    );
}

