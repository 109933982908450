import React, { useEffect, useState } from "react";

// Importando estilo
import "./Product.css";
import { RaffledProductInterface } from "../../Interfaces/RaffledProductInterface";
import { FormatDateMonth } from "../../Utils/FormartDateMonth";

interface ProductProps {
    position: string, // Si es column, se muestra en columna, si es row, se muestra en fila
    footer?: boolean, // Si es true, se muestra el footer
    win?: boolean, // Si es true, se muestra el footer con el detalle del ganador
    footer2?: boolean // Si es true, se muestra el footer con el detalle del ganador si va en la columna
    product: RaffledProductInterface // Producto a mostrar
    handleClick?: () => void;
}

export const Product: React.FC<ProductProps> = ({
    position = "column",
    footer = false,
    footer2 = false,
    win = false,
    handleClick=()=>{},
    product
}) => {

 
    return (
        <div className={`product-gradient ${position==='column' ? 'product-gradient-column' : 'product-gradient-row'}`} onClick={()=>handleClick()}> 
            <div className={`product ${position==='column' ? "product-background" : "product-background-style"} `}>
                <div className={`${position==='column' ? "product-column" : "product-row"}`}>
                    <img className={`${position==='column' ? "product-img-column" : "product-img-row" }`} src={product.productImg} alt="imagen" width={80}/>
                    <div className="product-detail">
                        <div className="tinytext product-text">Premio</div>
                        <div className={`${position==='column' ? "small-bold" : "smalltext-header  "} product-text product-text-title`}>{product.title}</div>
                        {position==='column' && <div className="tinytext product-text">Sorteo</div>}
                        <div className="tinytext product-text">{FormatDateMonth(product.drawDate.toDate())} / {product.drawHour} </div>
                    </div>
                    {footer && <div className={`${position==='column' ? "small-bold" : "smalltext"} product-status product-text`}>Lo quiero YAA</div>}
                </div>
                { footer2 && <div className={`${position==='column' ? "small-bold" : "tinytext-header"} product-status product-text`}>Lo quiero YAA</div>}

                { footer &&
                    <div className="product-footer">
                        {win ?  
                            <div className="product-footer-win">
                                <div className="product-win-item">
                                    <div className="tinytext product-text">Nombre</div>
                                    <div className="tinytext-header product-text">Gonzalo Hernández Osorio</div>
                                </div>
                                <div className="product-win-item">
                                    <div className="tinytext product-text">N.Documento</div>
                                    <div className="tinytext-header product-text">72566***</div>
                                </div>
                                <div className="product-win-item">
                                    <div className="tinytext product-text">País</div>
                                    <div className="tinytext-header product-text">Perú</div>
                                </div>
                            </div>:

                            <div className="smalltext-header product-text product-footer-item">
                                {product.status}
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
};
