import React, { useEffect, useRef } from "react";

import './ModalReadFuture.css';

import { ChargeSessionInterface } from "../../Interfaces/ChargeSessionInterface";
import { CreateNewCharge } from "../../Service/ChargeSession";

import { ModalCheckout } from "../ModelCheckout/ModalCheckout";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

interface ModalReadFutureProps {
    showReadFuture: boolean;
    setShowReadFuture: React.Dispatch<React.SetStateAction<boolean>>;
    readingPriceId: string;
    price: number;
    currency: string;
    email: string;
    userId: string;
}

export const ModalReadFuture: React.FC<ModalReadFutureProps> = ({
    showReadFuture,
    setShowReadFuture,
    readingPriceId,
    price,
    currency,
    email,
    userId
}) => {

    const [loading, setLoading] = React.useState<boolean>(false);

    const {userDetail} = useSelector((state: any) => state.user);

    const handlePayClick = (toke: string) => {
        console.log("🚀 ~ handlePayClick ~ toke:", toke)
        setLoading(true);
        const newCharge: ChargeSessionInterface = {
            token: toke,
            readingPriceId: readingPriceId,
            currency: currency,
            userId: userId,
        }
        CreateNewCharge(newCharge).then((response) => {
            console.log("🚀 ~ CreateNewCharge ~ response:", response)
            toast.success("Tu pago ha sido procesado correctamente, en breves momento recibirás un correo electrónico con la información", {autoClose: 6000});
            setLoading(false);
            setShowReadFuture(false);
        }).catch((error) => {
            console.log("🚀 ~ handlePayClick ~ error:", error);
            setLoading(false);
            toast.error("Error al procesar el pago, intentelo más tarde");
        });
        setLoading(false);
    }


    return (
        <ModalCheckout
            show={showReadFuture}
            setShow={setShowReadFuture}
            modalTitle="Estás a un paso de saber más sobre tu futuro"
            amount={price}
            email={userDetail.email}
            handleProcessCheckout={handlePayClick}
            itNumber={userDetail.itNumber}
            typeIt={userDetail.typeIt}
        />
    )
}